import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArticleContext } from '../article-provider';
import styles from './annotation-form.module.scss';

const AnnotationForm = ({
  existingAnnotation,
  highlightableRef,
  existingItems,
  allowExcerpt = true,
  onSubmit
}) => {
  const [editing, setEditing] = useState(existingItems === 0 ? true : false);
  const [annotation, setFormAnnotation] = useState(existingAnnotation);
  const [annotationSaveButton, setAnnotationSaveButton] = useState(true);
  const { addAnnotation } = useContext(ArticleContext);

  useEffect(() => {
    /**
     * Event listener: set annotation excerpt state when user highlights the correct area.
     */
    const didHighlight = () => {
      const selection = document.getSelection();
      const selectionStart = selection.anchorNode;

      // Don't proceed unless the selection originated in the highlightable node.
      if (!highlightableRef.current.contains(selectionStart)) {
        return false;
      }

      setFormAnnotation((prevAnnotations) => ({
        ...prevAnnotations,
        excerpt: document.getSelection().toString()
      }));
    };

    /**
     * Remove event listeners for highlight selectionchange.
     */
    const removeHighightListeners = () => {
      document.removeEventListener('selectionchange', didHighlight, false);
    };

    if (allowExcerpt) {
      if (editing) {
        document.addEventListener('selectionchange', didHighlight, false);
      } else {
        removeHighightListeners();
      }
    }

    // Remove listeners on component unmount.
    return removeHighightListeners;
  }, [editing, highlightableRef, allowExcerpt]);

  /**
   * Change handler: register changes to annotation body text from form.
   *
   * @param {string} value
   *   The text from the body text area.
   */
  const handleBodyChange = (value) => {
    setFormAnnotation((prevFormAnnotation) => ({
      ...prevFormAnnotation,
      body: value
    }));
    // Enable save button only if text was added.
    setAnnotationSaveButton(value.length > 0 ? false : true);
  };

  /**
   * Button handler: trigger saving the annotation.
   *
   * @param {object} e
   *   An event.
   */
  const handleSaveAnnotation = async (e) => {
    e.preventDefault();
    const id = await addAnnotation(annotation);
    setEditing(false);
    resetAnnotation();

    const newAnnotation = {
      ...annotation,
      id
    };

    // Execute onSubmit callback.
    if (onSubmit) {
      onSubmit(newAnnotation);
    }
  };

  /**
   * Clear annotation values in the form.
   *
   * Maintains articleId and user.
   */
  const resetAnnotation = () => {
    setFormAnnotation((prevAnnotation) => ({
      ...prevAnnotation,
      excerpt: '',
      body: ''
    }));
  };

  return (
    <form className={styles.annotationForm}>
      <h3 className={styles.title} data-count={existingItems}>
        Annotations
      </h3>
      {!editing && (
        <button className={styles.addTrigger} onClick={() => setEditing(true)}>
          Add an annotation...
        </button>
      )}

      {editing && (
        <fieldset>
          {allowExcerpt && (
            <div className={styles.excerpt}>
              {annotation.excerpt && <span>{annotation.excerpt}</span>}
              {!annotation.excerpt && (
                <p className={styles.excerptInstructions}>
                  ← Highlight some text to quote a specific passage&hellip;
                </p>
              )}
            </div>
          )}
          <textarea
            onChange={(e) => handleBodyChange(e.target.value)}
            placeholder={'Your feedback'}
            value={annotation.body}
          />
          <div className={styles.actions}>
            <button
              className={styles.actionCancel}
              onClick={() => setEditing(false)}
            >
              Cancel
            </button>
            <button
              className={styles.actionSave}
              onClick={(e) => handleSaveAnnotation(e)}
              disabled={annotationSaveButton}
            >
              Save Annotation
            </button>
          </div>
        </fieldset>
      )}
    </form>
  );
};

AnnotationForm.propTypes = {
  existingAnnotation: PropTypes.object.isRequired,
  highlightableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
};

export default AnnotationForm;
