import React, { useEffect, useContext, useRef, useCallback } from 'react';
import { ArticleContext } from '../article-provider';
import AnnotationForm from '../annotation-form';
import { CSSTransition } from 'react-transition-group';
import { ArticleDisplayStateContext } from '../article-display-state-provider';
import { getOffsetTop } from '../../utils';
import { format } from 'date-fns';

const AnnotationDisplay = ({ annotation, sectionLink }) => {
  const { setActiveAnnotationChunkUi, setArticleSectionStatus } = useContext(
    ArticleDisplayStateContext
  );

  const focusAnnotation = (e) => {
    e.preventDefault();
    const chunkParts = annotation.chunkId.split('.');
    chunkParts.pop();

    // Set the section to open, and possibly the parent section too.
    if (chunkParts[0]) {
      setArticleSectionStatus(chunkParts[0], true);
    }
    if (chunkParts[1]) {
      setArticleSectionStatus(chunkParts.join('--'), true);
    }

    setActiveAnnotationChunkUi(annotation.chunkId);
    const annotationUiElement = document.getElementById(annotation.chunkId);

    // Scroll to the correct section after a short delay.
    setTimeout(() => {
      window.scroll({
        top: getOffsetTop(annotationUiElement) - 135,
        behavior: 'smooth'
      });
    }, 10);
  };

  const showSectionLink = sectionLink && annotation.chunkId ? true : false;

  return (
    <li
      className={'notes-widget__annotation-entry'}
      id={
        sectionLink
          ? `annotation-lower-item--${annotation.id}`
          : `annotation-item--${annotation.id}`
      }
    >
      {annotation.authorMetadata && (
        <div className={'notes-widget__annotation-entry-author'}>
          {`${annotation.authorMetadata.firstName} ${annotation.authorMetadata.lastName}`}
        </div>
      )}
      <p className={'notes-widget__annotation-entry-date'}>
        {format(new Date(annotation.created), 'L/d/yyyy')}
      </p>
      {showSectionLink && (
        <a
          className={'notes-widget__annotation-section-link'}
          href={`#${annotation.chunkId}`}
          onClick={(e) => focusAnnotation(e)}
        >
          <span className="notes-widget__trigger-text-existing"></span>
        </a>
      )}
      {annotation.excerpt && (
        <p className={'notes-widget__annotation-entry-excerpt'}>
          <span>{annotation.excerpt}</span>
        </p>
      )}
      <p className={'notes-widget__annotation-entry-body'}>{annotation.body}</p>
    </li>
  );
};

export const AnnotationsList = ({ annotations, sectionLink = false }) => {
  return (
    <>
      {annotations.length > 0 && (
        <ul className={'notes-widget__annotation-entries'}>
          {annotations
            .sort((a, b) => (a.created > b.created ? 1 : -1))
            .map((annotation) => (
              <AnnotationDisplay
                key={annotation.id}
                annotation={annotation}
                sectionLink={sectionLink}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const NotesWidget = ({ children, annotations, chunkId }) => {
  const { activeAnnotationChunkUi, setActiveAnnotationChunkUi } = useContext(
    ArticleDisplayStateContext
  );
  const { newAnnotationStub } = useContext(ArticleContext);
  const highlightableRef = useRef(null);

  const setExpanded = () => setActiveAnnotationChunkUi(chunkId);

  const expanded = activeAnnotationChunkUi === chunkId;

  const setClosed = useCallback(() => setActiveAnnotationChunkUi(null), [
    setActiveAnnotationChunkUi
  ]);

  useEffect(() => {
    const didEscKey = (e) => {
      if (e.keyCode === 27) {
        setClosed();
      }
    };

    if (expanded) {
      document.body.classList.add('notes-widget-active');
      document.addEventListener('keydown', didEscKey, false);
    } else {
      document.body.classList.remove('notes-widget-active');
      document.removeEventListener('keydown', didEscKey, false);
    }
  }, [expanded, setClosed]);

  const chunkIdReduced = chunkId.replace(/-/g, ' ').split('.');
  chunkIdReduced.pop();
  const chunkToString = chunkIdReduced.join(' ');

  return (
    <>
      <div className={'notes-widget'} data-expanded={expanded}>
        <div className={'notes-widget__content'}>{children}</div>
        {!expanded && (
          <div className={'notes-widget__trigger'}>
            <button
              onClick={setExpanded}
              className={
                annotations.length > 0
                  ? 'notes-widget__trigger-existing'
                  : 'notes-widget__trigger-new'
              }
              aria-controls={`notes-widget-${chunkId}`}
              aria-expanded={expanded}
            >
              {annotations.length > 0 ? (
                <span className={'notes-widget__trigger-text-existing'}>
                  <span>
                    {`${annotations.length} annotation(s) have been added to ${chunkToString}. Click to add another annotation.`}
                  </span>
                </span>
              ) : (
                <span className={'notes-widget__trigger-text-new'}>
                  <span>Add an annotation to {chunkToString}.</span>
                </span>
              )}
            </button>
          </div>
        )}
        <CSSTransition
          in={expanded}
          timeout={200}
          classNames="note"
          unmountOnExit
          onEnter={setExpanded}
          onExited={setClosed}
        >
          <div
            className={'notes-widget__entry-wrapper'}
            id={`notes-widget-${chunkId}`}
            aria-hidden={!expanded}
          >
            <div className={'notes-widget__screen'}></div>
            <div className={'notes-widget__entry-form-wrapper'}>
              <button
                className={'notes-widget__entry-form-close'}
                onClick={setClosed}
                aria-controls={`notes-widget-${chunkId}`}
                aria-expanded={expanded}
              >
                <span className={'notes-widget__entry-form-close-text'}>
                  Cancel annotation
                </span>
                <span></span>
                <span></span>
              </button>
              <div
                className={'notes-widget__focused-chunk'}
                ref={highlightableRef}
              >
                <div className={'notes-widget__focused-chunk-container'}>
                  {children}
                </div>
              </div>
              <div className="notes-widget__entry-form">
                <AnnotationForm
                  existingAnnotation={newAnnotationStub(chunkId)}
                  highlightableRef={highlightableRef}
                  existingItems={annotations.length}
                />
                <AnnotationsList annotations={annotations} />
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default NotesWidget;
