import React, { useContext } from 'react';
import { getOffsetTop } from '../../utils';
import { ArticleDisplayStateContext } from '../article-display-state-provider';
import styles from './footnote-marker.module.scss';

const FootnoteMarker = ({ id, content, parent }) => {
  const { setArticleSectionStatus } = useContext(ArticleDisplayStateContext);

  const openAndScrollTo = ({ id, e }) => {
    e.preventDefault();
    setArticleSectionStatus(`notes`, true);
    const note = document.getElementById(`article-bottom-footnote--${id}`);
    // Add slight delay to make sure section is open
    // before scroll is fired.
    setTimeout(() => {
      window.scroll({
        top: getOffsetTop(note) - 135,
        behavior: 'smooth'
      });
    }, 10);
  };

  return (
    <span
      className={styles.footnote + ' footnote'}
      data-footnote-id={id}
      data-footnote-parent={parent}
      id={`footnote-marker-${id}`}
    >
      <sup>
        {/* 'FN' is replaced with a number via an effect in ArticleLayout, so that all the footnotes are ordered numerically. */}
        {/* onClick opens footnotes section and scrolls down */}
        <a
          onClick={(e) => openAndScrollTo({ id, e })}
          href={`#footnote-content-${id}`}
        >
          FN
        </a>
      </sup>
      {/* Similar to the marker, an affect in ArticleLayout gathers all of the footnote content so that it can be displayed */}
      <span style={{ display: 'none' }}>{content}</span>
    </span>
  );
};

export default FootnoteMarker;
