import React, { useContext, useEffect } from 'react';
import ArticleSection from '../article-section';
import { TocTrackedH2 } from '../toc-tracked-heading/toc-tracked-heading';
import { ArticleDisplayStateContext } from '../article-display-state-provider';
import { getIdString } from '../../utils';

const ArticleSectionNonCollapsible = ({ title, children }) => {
  const idString = getIdString([title]);

  const { articleSectionStatuses, setArticleSectionStatus } = useContext(
    ArticleDisplayStateContext
  );

  // Initialize section title as visible. This is necessary because the table of contents
  // needs to know whether to open a section when we scroll to it.
  useEffect(() => {
    if (articleSectionStatuses[idString] === undefined) {
      setArticleSectionStatus(idString, true);
    }
  });

  return (
    <ArticleSection id={[title]}>
      <TocTrackedH2 id={[title]}>{title}</TocTrackedH2>
      {children}
    </ArticleSection>
  );
};

export default ArticleSectionNonCollapsible;
