import React from 'react';
import classNames from 'classnames';
import { getIdString } from '../../utils';

import styles from './toc-tracked-heading.module.scss';

const TocTrackedHeading = ({ Element, id, text, countDisplay, modifier }) => {
  const classes = {
    'tracked-heading': true,
    'tracked-heading-top': id.length === 1,
    [styles.headingFirst]: id.length === 1,
    [styles.headingSmall]: modifier === 'small',
    [styles.headingHeavy]: modifier === 'collapsible-deep-nested'
  };

  return (
    <Element
      id={`heading--${getIdString(id)}`}
      className={classNames(styles.heading, classes)}
      data-level={id.length}
    >
      <span className={'tracked-heading__text'}>{text}</span>
      {countDisplay && (
        <span className={classNames('tracked-heading__count', styles.count)}>
          {countDisplay}
        </span>
      )}
    </Element>
  );
};

export const TocTrackedH4 = ({ id, children, countDisplay, modifier }) => (
  <TocTrackedHeading
    Element={`h4`}
    id={id}
    text={children}
    countDisplay={countDisplay}
    modifier={modifier}
  />
);

export const TocTrackedH3 = ({ id, children, countDisplay, modifier }) => (
  <TocTrackedHeading
    Element={`h3`}
    id={id}
    text={children}
    countDisplay={countDisplay}
    modifier={modifier}
  />
);

export const TocTrackedH2 = ({ id, children, countDisplay, modifier }) => (
  <TocTrackedHeading
    Element={`h2`}
    id={id}
    text={children}
    countDisplay={countDisplay}
    modifier={modifier}
  />
);
