import React from 'react';
import classNames from 'classnames';
import { getIdString } from '../../utils';

import styles from './article-section.module.scss';

const ArticleSection = ({ children, id, modifier }) => {
  const classes = {
    [styles.intro]: modifier === 'intro',
    [styles.collapsibleNested]: modifier === 'collapsible-nested',
    [styles.collapsibleDeepNested]: modifier === 'collapsible-deep-nested',
    'toc-tracked-section': true
  };
  return (
    <div id={getIdString(id)} className={classNames(styles.wrapper, classes)}>
      {children}
    </div>
  );
};

export default ArticleSection;
