import React, { useEffect, useState, useContext, useCallback } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { AuthContext } from '../auth-provider';

export const ArticleContext = React.createContext({});

const ArticleProvider = ({ children, articleId }) => {
  const [annotations, setAnnotations] = useState(null);
  const [ready, setReady] = useState(null);
  const { currentUser, onlyUserMetadata } = useContext(AuthContext);

  /**
   * Add a new annotation.
   *
   * @param {*} annotation
   */
  const addAnnotation = async (annotation) => {
    // Set annotation timestamp.
    annotation.created = Date.now();
    const ref = await firebase
      .firestore()
      .collection('annotations')
      .add(annotation);

    return ref.id;
  };

  const newAnnotationStub = (chunkId) => ({
    authorId: currentUser.uid,
    authorMetadata: onlyUserMetadata(currentUser),
    body: '',
    chunkId: chunkId ? chunkId : '',
    articleId,
    excerpt: '',
    status: true
  });

  /**
   * Sets annotation state by merging new annotations with existing.
   *
   * @param {Array} newAnnotations
   *   A list of annotations. See getAnnotations().
   */
  const mergeAnnotations = (newAnnotations) => {
    setAnnotations((prevAnnotations) => {
      if (!prevAnnotations) {
        return newAnnotations;
      }

      // Return the set of unique annotations.
      return Object.values(
        [...prevAnnotations]
          .concat(newAnnotations)
          .reduce((uniqueAnnotations, currentAnnotation) => {
            uniqueAnnotations[currentAnnotation.id] = currentAnnotation;
            return uniqueAnnotations;
          }, {})
      );
    });
  };

  /**
   * Get annotations from Firestore.
   *
   * @param {*} queryResolver
   *   Resolver that allows other callers to update the query before it is sent.
   */
  const getAnnotations = useCallback(
    (queryResolver) => {
      // A note about this query - we rely on security rules to enforce correct
      // privacy of data based on status or per-user visibility settings. Each query must
      // restrict its set to not return items that will fail security rules. See
      // https://firebase.google.com/docs/firestore/security/rules-query, and remember:
      // "Rules are not filters."
      const baseQuery = firebase
        .firestore()
        .collection('annotations')
        .where('articleId', '==', articleId)
        .where('status', '==', true);

      queryResolver(baseQuery).onSnapshot((querySnapshot) => {
        let snapshotAnnotations = [];
        querySnapshot.forEach((doc) =>
          snapshotAnnotations.push({
            ...doc.data(),
            id: doc.id
          })
        );
        mergeAnnotations(snapshotAnnotations);
        setReady(true);
      });
    },
    [articleId]
  );

  // Listen for public annotations.
  useEffect(() => {
    if (currentUser) {
      return getAnnotations((baseQuery) =>
        baseQuery.where('authorMetadata.annotationsVisibility', '==', 'visible')
      );
    }
  }, [currentUser, articleId, getAnnotations]);

  // Listen for private annotations. Firestore cannot do OR queries, so we do a separate
  // request for the annotations that belong to a particular user that they've marked
  // private.
  useEffect(() => {
    if (currentUser) {
      return getAnnotations((baseQuery) =>
        baseQuery
          .where('authorMetadata.annotationsVisibility', '==', 'private')
          .where('authorId', '==', currentUser.uid)
      );
    }
  }, [currentUser, articleId, getAnnotations]);

  const getAnnotationsByChunk = (chunkId) =>
    annotations
      ? annotations.filter((annotation) => annotation.chunkId === chunkId)
      : null;

  return (
    <ArticleContext.Provider
      value={{
        annotations,
        getAnnotationsByChunk,
        newAnnotationStub,
        addAnnotation,
        articleId,
        ready
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export default ArticleProvider;
