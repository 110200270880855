import React, { useState } from 'react';
export const ArticleDisplayStateContext = React.createContext({});

const ArticleDisplayStateProvider = ({ children }) => {
  // Represents which sections of the article are currently visible.
  const [articleSectionStatuses, setArticleSectionStatuses] = useState({});

  // Represents which sections of the table of contents are currently visible.
  const [tocSectionsVisibility, setTocSectionsVisibility] = useState({});

  // Indicates the chunk-related annotation widget that is currently open.
  const [activeAnnotationChunkUi, setActiveAnnotationChunkUi] = useState(null);

  const setArticleSectionStatus = (sectionId, status) => {
    setArticleSectionStatuses((prevSectionsVisibility) => {
      return {
        ...prevSectionsVisibility,
        [sectionId]: status
      };
    });
  };

  const setTocSectionVisibility = (sectionId, status) => {
    setTocSectionsVisibility((prevTocSectionsVisibility) => {
      return {
        ...prevTocSectionsVisibility,
        [sectionId]: status
      };
    });
  };

  const hideAllTocSections = () => {
    setTocSectionsVisibility({});
  };

  return (
    // We set up section visibility as a context because the TOC has to be able to
    // control it as well as the headings for the individual sections.
    <ArticleDisplayStateContext.Provider
      value={{
        articleSectionStatuses,
        setArticleSectionStatus,
        tocSectionsVisibility,
        hideAllTocSections,
        setTocSectionVisibility,
        setActiveAnnotationChunkUi,
        activeAnnotationChunkUi
      }}
    >
      {children}
    </ArticleDisplayStateContext.Provider>
  );
};

export default ArticleDisplayStateProvider;
