import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { buildNotableId, getIdString } from '../../utils';
import FootnoteMarker from '../footnote-marker';
import Notable from '../notable';
import classnames from 'classnames';
import styles from './wysiwyg.module.scss';

const WYSIWYG = ({ context, className, body }) => {
  const transform = (node, index) => {
    // Here we transform the wysiwyig markup into a react element.
    if (node.type === 'tag' && node?.attribs?.class === 'footnote') {
      const footnoteId = node.attribs['data-footnote-id'];

      // Get the footnote content.
      const content = node.children.find(
        (child) => child.attribs?.class === 'footnote__content'
      );
      if (!content) {
        return null;
      }
      return (
        <FootnoteMarker
          id={footnoteId}
          content={convertNodeToElement(content, index, transform)}
          parent={getIdString(context)}
          key={footnoteId}
        />
      );
    }
    // Elements that receive annotation application.
    const annotationTypes = ['p', 'ul', 'ol', 'table', 'div', 'figure'];
    // Only apply annotations to top-level elements included in annotationTypes array,
    // and if context exists.
    if (
      node.type === 'tag' &&
      annotationTypes.includes(node.name) &&
      node.parent === null &&
      context
    ) {
      const id = buildNotableId(context, index);
      return (
        <Notable id={id} key={id}>
          {convertNodeToElement(node, index, transform)}
        </Notable>
      );
    }
  };

  return (
    <div
      className={classnames(styles.wysiwyg, {
        [className]: className
      })}
    >
      {ReactHtmlParser(body, {
        transform
      })}
    </div>
  );
};

WYSIWYG.propTypes = {
  /* HTML as a string to be rendered, from a WYSIWYG field. */
  body: PropTypes.string,
  className: PropTypes.string,
  context: PropTypes.array
};

export default WYSIWYG;
