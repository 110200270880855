import React, { useContext } from 'react';
import slugify from 'slugify';
import { AuthContext } from '../auth-provider';
import { ArticleContext } from '../article-provider';
import NotesWidget from '../notes-widget/notes-widget';
import styles from './notable.module.scss';

const Notable = ({ children, id }) => {
  const { currentUser } = useContext(AuthContext);
  const { getAnnotationsByChunk, ready } = useContext(ArticleContext);
  const chunkId = slugify(id).toLowerCase();
  const annotations = getAnnotationsByChunk(chunkId);
  const showNotesWidget = currentUser && ready;

  return (
    <div id={chunkId} className={styles.wrapper}>
      {showNotesWidget ? (
        <NotesWidget annotations={annotations} chunkId={chunkId}>
          {children}
        </NotesWidget>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default Notable;
